import { getSortedHazardFeatures, MapboxSource, MapMode, useHazardsStore, useMapStore } from '@/features/hazards';
import { LngLatLike, Marker } from 'mapbox-gl';
import { h, render } from 'vue';
import UiMapClusterMarker from '../components/UiMap/UiMapClusterMarker/UiMapClusterMarker.vue';
import UiMapMarker from '../components/UiMap/UiMapMarker.vue';

import { promisify } from 'es6-promisify';
import { Point } from 'geojson';

export const updateMarkers = async () => {
  /**
   * Function derived from https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
   */

  const map = useMapStore();
  const hazards = useHazardsStore();
  const mapbox = map.mapbox!;

  if (hazards.mapMode !== MapMode.Default) {
    for (const id in map.clusterMarkersOnScreen) {
      map.clusterMarkersOnScreen[id].remove();
      delete map.clusterMarkersOnScreen[id];
    }

    for (const id in map.clusterMarkers) {
      delete map.clusterMarkers[id];
    }
    return;
  }

  const newMarkers: Record<string, Marker> = {};
  const source = mapbox.getSource(MapboxSource.hazardsMarkersSource);
  if (!source || source.type != 'geojson') return;

  const clusterFeatures = mapbox.querySourceFeatures(MapboxSource.hazardsMarkersSource);

  // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
  // and add it to the map if it's not there already
  for (const feature of clusterFeatures) {
    const coords = (feature.geometry as Point).coordinates as LngLatLike;

    const props = feature.properties!;

    if (!props.cluster) {
      const el = document.createElement('div');
      let marker = map.clusterMarkers[props.id];
      if (!marker) {
        marker = map.clusterMarkers[props.id] = new Marker({
          element: el,
        }).setLngLat(coords);

        const clusterMarkerComponent = h(UiMapMarker, {
          feature,
          marker,
        });
        render(clusterMarkerComponent, el);
      }

      newMarkers[props.id] = marker;
      if (!map.clusterMarkersOnScreen[props.id]) marker.addTo(mapbox);
      continue;
    }

    const clusterId = props.cluster_id;
    const clusterLeaves = await promisify(source.getClusterLeaves).call(source, clusterId, Infinity, 0);

    const sortedClusterHazards = getSortedHazardFeatures(clusterLeaves);

    if (sortedClusterHazards.length < 1) continue;

    let marker = map.clusterMarkers[clusterId];

    if (!marker) {
      const el = document.createElement('div');

      marker = map.clusterMarkers[clusterId] = new Marker({
        element: el,
      }).setLngLat(coords);

      const clusterMarkerComponent = h(UiMapClusterMarker, {
        coords,
        marker,
        sortedClusterHazards,
        clusterLeaves,
      });
      render(clusterMarkerComponent, el);
    }

    newMarkers[clusterId] = marker;
    if (!map.clusterMarkersOnScreen[clusterId]) marker.addTo(mapbox);
  }

  // for every marker we've added previously, remove those that are no longer visible
  for (const id in map.clusterMarkersOnScreen) {
    if (!newMarkers[id]) map.clusterMarkersOnScreen[id].remove();
  }
  map.clusterMarkersOnScreen = newMarkers;
};
