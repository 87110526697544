import { PrescribedBurnEntity } from './../../domain/entities/prescribed-burn-entity';
import { ApiFetchError, BaseError, UnimplementedError } from '@/common/errors';
import {
  BaseHazardEntity,
  CentreEntity,
  CentreSubType,
  ClosureEntity,
  ClosureSubType,
  EnrichedWarningEntity,
  EntitySubType,
  EventEntity,
  FdrSubType,
  TotalFireBanEntity,
  GetCentresModel,
  GetClosuresModel,
  GetEventsModel,
  GetHazardsModel,
  GetIncidentsModel,
  GetS3HazardsModel,
  GetWarningByIdModel,
  GetWarningsModel,
  HAZARD_MARKERS,
  HazardEntity,
  HazardType,
  HazardsDataSource,
  HazardsRepository,
  IncidentEntity,
  IncidentSubType,
  S3HazardsDataSource,
  WarningEntity,
  WarningSubType,
  centresToSchemeMap,
  closuresToSchemeMap,
  incidentsToSchemeMap,
  localAreaGovernments,
  warningsToSchemeMap,
  HazardPriorityMapping,
  IconName,
  WarningSubTypes,
} from '@/features/hazards';

import { Either, left, right } from 'fp-ts/lib/Either';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { DateTime } from 'luxon';
import { FireDangerWarningsScheme, GetTotalFireBansModel, fireDangerWarningsToSchemeMap } from '../models';

import { composeFireBanLga } from '@/common/helpers/compose-fire-ban-lga';
import { captureException } from '@sentry/vue';

type FdrForecastArr = (FireDangerWarningsScheme & { fdrSubType: FdrSubType })[];

export class HazardsRepositoryImpl implements HazardsRepository {
  dataSource: HazardsDataSource;
  s3DataSource: S3HazardsDataSource;

  constructor(dataSource: HazardsDataSource, s3DataSource: S3HazardsDataSource) {
    this.dataSource = dataSource;
    this.s3DataSource = s3DataSource;
  }

  /**
   * @deprecated - The getEvents method should be used instead to get the event details.
   * @param hazardType
   * @param hazardId
   * @returns
   */
  async getHazardById(hazardType: HazardType, hazardId: string): Promise<Either<BaseError, EnrichedWarningEntity>> {
    try {
      let hazardModel: GetWarningByIdModel | undefined;

      switch (hazardType) {
        case HazardType.Warning:
          hazardModel = await this.dataSource.getWarningById(hazardId);
          break;
        default:
          throw new UnimplementedError(`get ${hazardType} by id`);
      }

      return right(hazardModel);
    } catch (e) {
      captureException(e);

      if (e instanceof BaseError) {
        return left(e);
      }

      return left(new ApiFetchError(`${hazardType} - ${hazardId}`));
    }
  }

  async getHazards(hazardType: HazardType): Promise<Either<BaseError, HazardEntity[]>> {
    try {
      let hazardModels: GetHazardsModel[] = [];
      let s3HazardModel: GetS3HazardsModel | null = null;

      switch (hazardType) {
        case HazardType.Incident:
          hazardModels = await this.dataSource.getIncidents();
          break;
        case HazardType.Warning:
          hazardModels = await this.dataSource.getWarnings();
          break;
        case HazardType.Centre:
          hazardModels = await this.dataSource.getCentres();
          break;
        case HazardType.Closure:
          hazardModels = await this.dataSource.getClosures();
          break;
        case HazardType.Event:
          hazardModels = await this.dataSource.getEvents();
          break;
        case HazardType.TotalFireBans:
          hazardModels = await this.dataSource.getTotalFireBans();
          break;
        case HazardType.FireDangerRating:
          s3HazardModel = await this.s3DataSource.getFireDangerRatings();
          break;
        case HazardType.PrescribedBurns:
          s3HazardModel = await this.s3DataSource.getPrescribedBurns();
          break;
        default:
          throw new UnimplementedError(`get ${hazardType}`);
      }

      if (s3HazardModel) return right(this.sortHazardEntities(this.mapS3ModelsToEntities(hazardType, s3HazardModel)));

      return right(this.mapModelsToEntities({ hazardModels, hazardType }));
    } catch (e) {
      captureException(e);
      return left(new ApiFetchError(hazardType));
    }
  }

  // API Models
  mapModelsToEntities({ hazardModels, hazardType }: { hazardModels: GetHazardsModel[]; hazardType: HazardType }): HazardEntity[] {
    switch (hazardType) {
      case HazardType.Warning:
        return hazardModels.map((model) => this.mapModelToEntity(model));
      case HazardType.Incident:
      case HazardType.Centre:
      case HazardType.Closure:
      case HazardType.Event:
        return this.sortHazardEntities(hazardModels.map((model) => this.mapModelToEntity(model)));
      case HazardType.TotalFireBans:
        return this.mapTotalFireBanModelToEntity(hazardModels as GetTotalFireBansModel[]);
      default:
        throw new UnimplementedError(`get ${hazardType}`);
    }
  }

  mapModelToEntity(hazardModel: GetHazardsModel): HazardEntity {
    const geoSource = hazardModel?.['geo-source'] as FeatureCollection;
    const geoSourceFeatures = geoSource?.['features'] ?? [];
    const point = geoSourceFeatures.filter((feature) => feature.geometry.type === 'Point').at(0);

    const polygons = geoSourceFeatures.filter((feature) => feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon');

    const baseHazardEntity: BaseHazardEntity = {
      id: hazardModel.id!,
      entityType: hazardModel.entityType as HazardType,
      entitySubType: hazardModel.entitySubType as (typeof EntitySubType)[keyof typeof EntitySubType],

      eventId: hazardModel.event!,

      title: hazardModel.name,
      publishedAt: hazardModel['published-date-time'],

      pointFeature: point ? this.enrichFeature(point, hazardModel) : undefined,
      polygonFeatures: polygons ? this.enrichFeatures(polygons, hazardModel) : undefined,
    };

    let partialHazardEntity: Partial<HazardEntity> = {};

    switch (hazardModel.entityType) {
      case HazardType.Warning:
        partialHazardEntity = this.mapWarningModelToPartialEntity(hazardModel as GetWarningsModel);
        break;
      case HazardType.Incident:
        partialHazardEntity = this.mapIncidentModelToPartialEntity(hazardModel as GetIncidentsModel);
        break;
      case HazardType.Centre:
        partialHazardEntity = this.mapCentreModelToPartialEntity(hazardModel as GetCentresModel);
        break;
      case HazardType.Closure:
        partialHazardEntity = this.mapClosureModelToPartialEntity(hazardModel as GetClosuresModel);
        break;
      case HazardType.Event:
        partialHazardEntity = this.mapEventModelToPartialEntity(hazardModel as GetEventsModel);
        break;

      default:
        break;
    }

    const hazardEntity: HazardEntity = { ...baseHazardEntity, ...(partialHazardEntity as HazardEntity) };

    return hazardEntity;
  }

  mapEventModelToPartialEntity(eventModel: GetEventsModel): Partial<EventEntity> {
    const banDate = eventModel['date-of-ban'];
    return {
      publishedAt: eventModel['published-date-time'],
      issuingAgency: eventModel['issuing-agency'],
      eventSummary: eventModel['web-message'],
      eventSummaryText: eventModel['web-text-message'],
      dateOfBan: banDate ? DateTime.fromISO(banDate) : undefined,
      banInformation: eventModel['ban-information'],
      rawEvent: eventModel,
    };
  }

  /**
   * Sort hazard entities by priority and, if needed, the published date.
   */
  sortHazardEntities(hazardEntities: HazardEntity[], hazardType?: HazardType): HazardEntity[] {
    return hazardEntities.sort((a, b) => {
      const aPriority = HazardPriorityMapping[a.entitySubType as (typeof EntitySubType)[keyof typeof EntitySubType]] ?? 0;
      const bPriority = HazardPriorityMapping[b.entitySubType as (typeof EntitySubType)[keyof typeof EntitySubType]] ?? 0;

      /**
       * This is to ensure warnings of the same type (Bushfire) are sorted by priority.
       * @example
       *  - x Emergency Warning comes before x Watch and Act Warning
       */
      if (Math.floor(aPriority) !== Math.floor(bPriority)) {
        return bPriority - aPriority;
      }

      /**
       * If the hazard type is Warning, we attempt to sort by the `sorting-priority` property.
       */

      if (hazardType === HazardType.Warning) {
        const aSortingPriority = (a as WarningEntity).sortingPriority ?? 0;
        const bSortingPriority = (b as WarningEntity).sortingPriority ?? 0;

        return aSortingPriority - bSortingPriority;
      }

      // Sort by published date if the priority is the same
      const aPublishedAt = a.publishedAt as string;
      const bPublishedAt = b.publishedAt as string;

      if (aPublishedAt && bPublishedAt) {
        const aMillis = DateTime.fromISO(aPublishedAt).toMillis();
        const bMillis = DateTime.fromISO(bPublishedAt).toMillis();

        return bMillis - aMillis;
      }

      /**
       * This is to ensure warnings of different types (Bushfire, Flood, etc) but the same alert level (Emergency, Watch and Act, etc)
       * are sorted by priority.
       * @example
       *  - Bushfire Emergency Warning comes before Flood Emergency Warning
       */
      if (aPriority !== bPriority) {
        return bPriority - aPriority;
      }

      // Sort by title (alphabetically) if the published date is the same
      const aTitle = a.title;
      const bTitle = b.title;

      if (aTitle && bTitle) {
        return aTitle.localeCompare(bTitle);
      }

      return 0;
    });
  }

  mapWarningModelToPartialEntity(warningModel: GetWarningsModel): Partial<WarningEntity> {
    const entitySubType = warningModel['entitySubType'];
    const scheme = warningsToSchemeMap[entitySubType as WarningSubType];

    if (entitySubType === WarningSubTypes.OtherWarningWarningEntity && warningModel['warning-icon']) {
      scheme.icon = HAZARD_MARKERS[warningModel['warning-icon'] as IconName] ?? scheme.icon;
    }

    return {
      ...scheme,
      description: this.composeWarningDescription(warningModel),
      warningType: warningModel['warning-type'],
      publishingStatus: warningModel['publishing-status'],
      geoSource: warningModel['geo-source'],
      alertLine: warningModel['alert-line'],
      safeToLeave: warningModel['safe-to-leave'],
      impactToHomesNote: warningModel['impact-to-homes-note'],
      telephoneWarning: warningModel['telephone-warning'],
      alertLevelNote: warningModel['alert-level-note'],
      threatToHomesNote: warningModel['threat-to-homes-note'],
      fireLocationNote: warningModel['fire-location-note'],
      fireDirectionNote: warningModel['fire-direction-note'],
      whatToDoNote: warningModel['what-to-do-note'],
      safestRouteNote: warningModel['safest-route-note'],
      sortingPriority: warningModel['sorting-priority'],
      actionStatement: warningModel['action-statement'],
    };
  }

  mapIncidentModelToPartialEntity(incidentModel: GetIncidentsModel): Partial<IncidentEntity> {
    const entitySubType = incidentModel['entitySubType'];
    const scheme = incidentsToSchemeMap[entitySubType as IncidentSubType];

    const incidentIcon = incidentModel['incident-icon'] as IconName | undefined;

    if (entitySubType === IncidentSubType.OtherIncidentEntity && incidentIcon) {
      scheme.icon = HAZARD_MARKERS[incidentIcon] ?? scheme?.icon;
    }

    return {
      ...scheme,
      lga: incidentModel['lga'],
      incidentType: incidentModel['incident-type'],
      reportedAt: incidentModel['start-date-time'],
      /**
       * The `updated-date-time` is used for both `publishedAt` and `updatedAt`.
       *
       * Some incidents don't have an `updated-date-time` and only have a `published-date-time`.
       * As such, we fallback to using the `published-date-time` if the `updated-date-time` is not available.
       */
      publishedAt: incidentModel['updated-date-time'] ?? incidentModel['published-date-time'],
      updatedAt: incidentModel['updated-date-time'] ?? incidentModel['published-date-time'],
      incidentStatus: incidentModel['incident-status'],
      description: this.composeIncidentDescription(incidentModel),
      reportedNear: this.composeIncidentReportedNear(incidentModel),
      reportedIn: incidentModel['suburbs']?.at(0)?.toUpperCase(),
    };
  }

  mapClosureModelToPartialEntity(closureModel: GetClosuresModel): Partial<ClosureEntity> {
    const entitySubType = closureModel['entitySubType'];
    const scheme = closuresToSchemeMap[entitySubType as ClosureSubType];

    return {
      ...scheme,
      description: closureModel['headline'] ?? '',
    };
  }

  mapCentreModelToPartialEntity(centreModel: GetClosuresModel): Partial<CentreEntity> {
    const entitySubType = centreModel['entitySubType'];
    const scheme = centresToSchemeMap[entitySubType as CentreSubType];

    return {
      ...scheme,
      description: centreModel['headline'] ?? '',
    };
  }

  mapTotalFireBanModelToEntity(totalFireBanModel: GetTotalFireBansModel[]): HazardEntity[] {
    const activeTotalFireBans = totalFireBanModel.map((model) => {
      const banDate = model['date-of-ban'];

      const banLevel = model['ban-level'];
      const isActive = banLevel === 'Ban';
      const rawLgaName = model['fire-ban-lga'];
      const sanitisedLga = composeFireBanLga(rawLgaName);

      const scheme =
        warningsToSchemeMap[isActive ? WarningSubTypes.TotalFireBanWarningEntity : WarningSubTypes.NoTotalFireBanWarningEntity];

      let description;

      switch (banLevel) {
        case 'Ban':
          description = 'Total Fire Ban';
          break;
        case 'No ban':
          description = 'No Total Fire Ban';
          break;
        case 'No ban - revoked':
          description = 'Total Fire Ban - Revoked';
          break;
        default:
          description = 'Total Fire Ban';
          break;
      }

      return {
        ...scheme,
        // We are using the rawLgaName as the id this enables us to
        // efficiently filter the map layers (given we're using tile ets)
        id: rawLgaName,
        banLevel,
        description,
        isActive,
        eventId: model.event!,
        title: sanitisedLga,
        rawLgaName,
        banDuration: model['ban-duration'],
        lga: sanitisedLga,
        entitySubType: model['entitySubType'] as WarningSubType,
        publishedAt: model['published-date-time'],
        entityType: HazardType.TotalFireBans,
        dateOfBan: banDate ? DateTime.fromISO(banDate) : undefined,
      } as TotalFireBanEntity;
    });

    // This will be used to enrich the TFB items with the inactive fire bans
    const inActiveTotalFireBans = localAreaGovernments.map((lga) => {
      const sanitisedLga = composeFireBanLga(lga);
      return {
        ...warningsToSchemeMap[WarningSubTypes.NoTotalFireBanWarningEntity],
        id: lga,
        title: sanitisedLga,
        eventId: '',
        description: 'No Total Fire Ban',
        banLevel: 'No ban',
        banDuration: 'All day',
        isActive: false,
        rawLgaName: lga,
        lga: sanitisedLga,
        polygonFeatures: [],
        entitySubType: WarningSubTypes.TotalFireBanWarningEntity,
        publishedAt: totalFireBanModel?.at(0)?.['published-date-time'],
        entityType: HazardType.TotalFireBans,
      } as TotalFireBanEntity;
    });

    // Group the fire ban by ban level, then sort each group alphabetically
    const activeFireBanItems = activeTotalFireBans.filter((item) => item.isActive).sort((a, b) => a.lga.localeCompare(b.lga));
    const inactiveRemoteFireBanItems = activeTotalFireBans.filter((item) => !item.isActive);
    const inactiveFireBanItems = inactiveRemoteFireBanItems
      .concat(inActiveTotalFireBans)
      .filter((item) => !item.isActive)
      .sort((a, b) => a.lga.localeCompare(b.lga));

    return activeFireBanItems.concat(inactiveFireBanItems);
  }

  // S3 Models
  mapS3ModelsToEntities(hazardType: HazardType, hazardModel: GetS3HazardsModel): HazardEntity[] {
    switch (hazardType) {
      case HazardType.FireDangerRating:
        return hazardModel.features.map((model) => this.mapFdrModelToEntity(model));
      case HazardType.PrescribedBurns:
        return hazardModel.features.map((model) => this.mapPbModelToEntity(model));
      default:
        throw new UnimplementedError(`get ${hazardType}`);
    }
  }

  mapFdrModelToEntity(feature: Feature<Geometry, GeoJsonProperties>): HazardEntity {
    const fdrForecastArr: FdrForecastArr = Array(4);

    Object.entries(feature.properties?.['forecasts']).forEach(([index, forecast]: [index: string, forecast: any]) => {
      let fdrSubType: FdrSubType;
      switch (forecast.fireDanger) {
        case 'Catastrophic':
          fdrSubType = FdrSubType.CatastrophicFdrEntity;
          break;
        case 'Extreme':
          fdrSubType = FdrSubType.ExtremeFdrEntity;
          break;
        case 'High':
          fdrSubType = FdrSubType.HighFdrEntity;
          break;
        case 'Moderate':
          fdrSubType = FdrSubType.ModerateFdrEntity;
          break;
        case 'No Rating':
        default:
          fdrSubType = FdrSubType.NoRatingFdrEntity;
          break;
      }

      fdrForecastArr[Number.parseInt(index)] = { ...fireDangerWarningsToSchemeMap[fdrSubType], fdrSubType: fdrSubType };
    });

    const baseHazardEntity: BaseHazardEntity = {
      id: feature.properties?.['AAC'],
      entityType: HazardType.FireDangerRating,
      title: feature.properties?.['DIST_NAME'],

      pointFeature: feature ? this.enrichFdrPointFeature(feature, fdrForecastArr) : undefined,
      polygonFeatures: feature ? [this.enrichFdrPolygonFeature(feature, fdrForecastArr)] : undefined,
    };

    return baseHazardEntity;
  }

  enrichFdrPointFeature(
    feature: Feature<Geometry, GeoJsonProperties>,
    fdrForecastArr: FdrForecastArr
  ): Feature<Geometry, GeoJsonProperties> {
    const newFeature: Feature<Geometry, GeoJsonProperties> = {
      ...feature,
      properties: {
        ...feature.properties,
        id: feature.properties?.['AAC'],
        schemes: fdrForecastArr.map((fdrForecast) => {
          return {
            icon: fdrForecast.icon,
            leadingColor: fdrForecast.leadingColor,
            backgroundColor: fdrForecast.backgroundColor,
            entitySubType: fdrForecast.fdrSubType,
          };
        }),
      },
    };
    return newFeature;
  }

  enrichFdrPolygonFeature(
    feature: Feature<Geometry, GeoJsonProperties>,
    fdrForecastArr: FdrForecastArr
  ): Feature<Geometry, GeoJsonProperties> {
    const newFeature: Feature<Geometry, GeoJsonProperties> = {
      ...feature,
      properties: {
        ...feature.properties,
        id: feature.properties?.['AAC'],
        schemes: fdrForecastArr.map((fdrForecast) => fdrForecast.polygonScheme),
      },
    };
    return newFeature;
  }

  mapPbModelToEntity(feature: Feature<Geometry, GeoJsonProperties>): HazardEntity {
    const burnCenterCoordinates = [
      (feature.properties?.['burn_target_long'] as number | undefined) ?? 0,
      (feature.properties?.['burn_target_lat'] as number | undefined) ?? 0,
    ];

    const baseHazardEntity: PrescribedBurnEntity = {
      id: feature.properties?.['burn_id'],
      entityType: HazardType.PrescribedBurns,
      title: 'Prescribed Burn',
      description: feature.properties?.['location'],
      icon: HAZARD_MARKERS['ew-prescribed-burn-or-burn-off'],

      burnTargetDate: feature.properties?.['burn_target_date'],
      publishedAt: DateTime.fromJSDate(new Date(feature.properties?.['burn_target_date_raw'])).toISO() ?? undefined,
      burnEstimatedStart: feature.properties?.['burn_est_start'],
      burnStatus: feature.properties?.['burn_stat'],
      burnLocation: feature.properties?.['location'],
      burnForestBlocks: feature.properties?.['forest_blocks'],
      burnArea: feature.properties?.['indicative_area'],
      burnPlannedAreaToday: feature.properties?.['burn_planned_area_today'],
      burnPlannedDistanceToday: feature.properties?.['burn_planned_distance_today'],
      burnCoordinates: [
        (feature.properties?.['burn_target_long'] as number | undefined) ?? 0,
        (feature.properties?.['burn_target_lat'] as number | undefined) ?? 0,
      ],
      burnPurpose: feature.properties?.['burn_purpose'],

      pointFeature: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: burnCenterCoordinates,
        },
        properties: {
          id: feature.properties?.['burn_id'],
          entityType: HazardType.PrescribedBurns,
          'icon-name': 'ew-prescribed-burn-or-burn-off',
        },
      },
      polygonFeatures: [feature],
    };

    return baseHazardEntity;
  }

  enrichFeatures(features: Feature<Geometry, GeoJsonProperties>[], hazardModel: GetHazardsModel) {
    return features.map((feature) => this.enrichFeature(feature, hazardModel));
  }

  enrichFeature(feature: Feature<Geometry, GeoJsonProperties>, hazardModel: GetHazardsModel): Feature<Geometry, GeoJsonProperties> {
    // Incident polygon features have `date-time` property that is used to determine the `fireShapeUpdatedAt` value.
    const fireShapeUpdatedAt = DateTime.fromISO(feature.properties?.['date-time'] ?? hazardModel['published-date-time']).toFormat(
      'dd/MM/yyyy HH:mm a'
    );

    return {
      ...feature,
      // TODO: Type the properties object
      properties: {
        ...feature.properties,
        id: hazardModel.id,
        entityType: hazardModel.entityType,
        entitySubType: hazardModel.entitySubType,
        eventId: hazardModel?.event,
        updatedAt: hazardModel['published-date-time'],
        fireShapeLabel: `Incident Area as at: ${fireShapeUpdatedAt}`,
      },
    };
  }

  composeIncidentReportedNear(incidentModel: GetIncidentsModel): string {
    const streetName = incidentModel['location']?.value;
    const nearestCrossStreet = incidentModel['nearest-cross-street']?.value;
    const suburb = incidentModel['suburbs']?.at(0)?.toUpperCase();

    let content = '';

    if (streetName) {
      content = `${streetName.toUpperCase()}`;
    }

    if (streetName && nearestCrossStreet) {
      content += ' and ';
    }

    if (nearestCrossStreet) {
      content += `${nearestCrossStreet.toUpperCase()}`;
    }

    if (suburb && content?.length) {
      content += `, in ${suburb}`;
    }

    return content;
  }

  composeIncidentDescription(incidentModel: GetIncidentsModel): string {
    const suburb = incidentModel['suburbs']?.at(0)?.toUpperCase() ?? '';
    const lgas = incidentModel['lga']?.map((lga) => lga.toUpperCase()).join(', ') ?? '';

    let content = '';

    if (suburb) {
      content = `${suburb}`;
    }

    if (suburb && lgas) {
      content += ', ';
    }

    if (lgas) {
      content += `${lgas}`;
    }

    return content;
  }

  /**
   * Compose warning description
   * @param warningModel - The warning model
   */
  composeWarningDescription(warningModel: GetWarningsModel): string {
    const actionStatement = warningModel['action-statement']?.toUpperCase();
    const headline = warningModel['headline'];

    if (actionStatement?.length) {
      return `${actionStatement} - ${headline}`;
    }

    return headline ?? '';
  }
}
